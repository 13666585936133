<template>
  <div class="columns login_grid_height m-0">
    <div class="column is-6 left-section">
      <div class="inside-left">
        <Carousel />
      </div>
    </div>
    <div class="column is-6 overlay-bg">
      <div class="column is-6 login-form">
        <img class="company-logo" src="/images/rise_logo_red.png" alt="" />
        <p class="main-text is-size-2">Forgot Password</p>
        <p class="child-text mb-4">
          Please input your email, and we will sent you a message to reset your
          password
        </p>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(submit)">
            <validation-provider
              name="email"
              v-slot="{ errors }"
              rules="required|email"
            >
              <b-field
                label="Email"
                :type="errors[0] && 'is-danger'"
                :message="errors[0]"
                class="input-field"
              >
                <b-input placeholder="e.g: admin@roketin.com" v-model="email" />
              </b-field>
            </validation-provider>
            <!-- <b-field v-if="isSuccess" class="column">
              <b-message type="is-success" has-icon>
                {{ successMessage }}
              </b-message>
            </b-field> -->
            <b-field class="is-flex is-justify-content-center login-cta mb-1">
              <b-button class="is-fullwidth" native-type="submit">
                Forgot Password
              </b-button>
            </b-field>
          </form>
        </ValidationObserver>

        <div class="register-cta is-flex">
          <router-link to="login" class="is-link">
            Remember your password?
            <span class="has-text-weight-semibold">Login here</span>
          </router-link>
        </div>

        <div class="text-footers">
          <p>
            PT Roketin Kreatif Teknologi. Copyright
            {{ new Date().getFullYear() }}.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/services/common/api.service'
import Carousel from '../../components/Carousel.vue'

export default {
  components: { Carousel },
  data() {
    return {
      email: '',
      isLoading: false,
      isSuccess: false,
      successMessage: 'Check your email to reset your password',
    }
  },
  methods: {
    emitEmail(val) {
      this.email = val
    },
    async submit() {
      this.isLoading = true
      try {
        await ApiService.post('/api/space-roketin/forgot-password', {
          workEmail: this.email,
        })
        this.isSuccess = true

        this.$swal({
          icon: 'success',
          titleText: 'Forgot Password',
          text: 'We just sent you an email with a password reset link',
          showConfirmButton: false,
          customClass: {
            popup: 'swal-forgot',
          },
        })
      } catch (err) {
        this.$swal({
          icon: 'error',
          titleText: err.response.data.meta.message,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'button is-primary',
          },
        })
      }
      this.isLoading = false

      this.email = ''
      requestAnimationFrame(() => {
        this.$refs.observer.reset()
      })
    },
  },
}
</script>
